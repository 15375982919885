import * as dateFns from "date-fns";
export var toSerializedDate = function toSerializedDate(date) {
  return date ? "".concat(date.getFullYear(), "-").concat("".concat(date.getMonth() + 1).padStart(2, "0")) : undefined;
};
export var fromSerializedDate = function fromSerializedDate(dateStr) {
  var parts = dateStr === null || dateStr === void 0 ? void 0 : dateStr.match(/(\d{4})-(\d{2})/);
  if (!parts) return undefined;
  return new Date(+parts[1], +parts[2] - 1);
};
export var DATE_FORMATS;

(function (DATE_FORMATS) {
  DATE_FORMATS["MONTH_AND_YEAR"] = "MMM yyyy";
  DATE_FORMATS["FULL_MONTH_AND_YEAR"] = "MMMM yyyy";
  DATE_FORMATS["NORMAL"] = "PPpp";
  DATE_FORMATS["ISO"] = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
})(DATE_FORMATS || (DATE_FORMATS = {}));

export var formatDate = function formatDate(inDate) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMATS.MONTH_AND_YEAR;
  if (!inDate) return "";
  var date = typeof inDate === "string" ? new Date(inDate) : inDate;
  return dateFns.format(date, format);
};
export var formatDateForMap = function formatDateForMap(inDate) {
  return formatDate(inDate);
};
export var formatDatesWithCurrent = function formatDatesWithCurrent() {
  var _dateRange$map;

  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      dateRange = _ref.date_range,
      isCurrent = _ref.is_current;

  var strCurrent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Current";
  if (!isCurrent && !(dateRange !== null && dateRange !== void 0 && dateRange.length)) return null;
  if (isCurrent && !(dateRange !== null && dateRange !== void 0 && dateRange.length)) return strCurrent;
  var parsedDates = (_dateRange$map = dateRange === null || dateRange === void 0 ? void 0 : dateRange.map(fromSerializedDate)) !== null && _dateRange$map !== void 0 ? _dateRange$map : [];
  var formattedDates = parsedDates.map(formatDateForMap);

  if (isCurrent) {
    if (!formattedDates[0]) return strCurrent;
    return "".concat(formattedDates[0], " to ").concat(strCurrent);
  }

  if (formattedDates[0] === formattedDates[1]) return formattedDates[0];
  return formattedDates.join(" to ");
};
export var ago = function ago(date) {
  if (!date) return "";
  return dateFns.formatDistance(dateFns.parseJSON(date), new Date(), {
    addSuffix: true
  });
};