import { useCallback } from "react";

var makeDataUrl = function makeDataUrl(data) {
  return "data:text/csv;charset=utf-8,".concat(encodeURIComponent(data));
};

export var useCsv = function useCsv(data, columns) {
  var withMagic = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var getCsvData = useCallback(function () {
    if (!data.length) return makeDataUrl("No data");
    var columnNames = columns !== null && columns !== void 0 ? columns : Object.keys(data[0]);
    var magickedColumnNames = withMagic ? columnNames.map(function (colName) {
      return colName.replace(/^./, function (m) {
        return m.toUpperCase();
      }).replace(/([^A-Z])([A-Z])/, function (_m, a, A) {
        return "".concat(a, " ").concat(A);
      }).replace(/[^a-z0-9 ]/gi, " ").replace(/\s\s+/g, " ").trim();
    }) : columnNames;
    var columnHeaders = magickedColumnNames.map(function (colName) {
      return JSON.stringify(colName);
    }).join(",");
    var csvData = data.map(function (row) {
      return columnNames.map(function (col) {
        return JSON.stringify(row[col]);
      }).join(",");
    }).join("\n");
    var exportData = columnHeaders + "\n" + csvData;
    return exportData;
  }, [columns, data, withMagic]);
  var run = useCallback(function () {
    var dataUrl = makeDataUrl(getCsvData());
    window.open(dataUrl, "_blank", "noreferrer noopener");
  }, [getCsvData]);
  return {
    getCsvData: getCsvData,
    run: run
  };
};