import _typeof from "@babel/runtime/helpers/typeof";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * get the differences between two objects or arrays
 * performs a shallow check
 * returns the differences in the latter object
 * eg: a -> { test: "abc", test2: 123 }
 *     b -> { test2: 456 }
 *     result -> { test: undefined, test2: 456 }
 * eg: a -> [1, 2, 3, 4, 5]
 *     b -> [1, 2, undefined, 8]
 *     result -> [undefined, undefined, undefined, 8, undefined]
 *
 * `ignoreMissing` has no effect on arrays
 * for objects, `ignoreMissing` will ignore keys that are not specified in
 * the latter object rather than treating them as differences
 *
 * use `hasDiff` to see if there are actual differences (most useful in arrays)
 */
export var diff = function diff(a, b) {
  var ignoreMissing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  typeCheck(a, b);
  var areArrays = Array.isArray(a) && Array.isArray(b);

  if (areArrays) {
    var arrayLength = Math.max(a.length, b.length);

    var _diffs = new Array(arrayLength).fill(undefined).map(function (_, i) {
      if (a[i] === b[i]) return undefined;
      return b[i];
    });

    return _diffs;
  } // object comparison


  var allKeys = Array.from(new Set([].concat(_toConsumableArray(Object.keys(a)), _toConsumableArray(Object.keys(b)))));
  var diffs = {};

  for (var _i = 0, _allKeys = allKeys; _i < _allKeys.length; _i++) {
    var key = _allKeys[_i];

    if (a[key] !== b[key]) {
      if (!(key in b)) {
        if (ignoreMissing) continue;
        diffs[key] = undefined;
      }

      diffs[key] = b[key];
    }
  }

  return diffs;
};
export var hasDiff = function hasDiff(a, b) {
  typeCheck(a, b);

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return true;
    if (diff(a, b).filter(function (e) {
      return !!e;
    }).length) return true;
    return false;
  }

  if (Object.keys(diff(a, b)).length) return true;
  return false;
};

var typeCheck = function typeCheck(a, b) {
  var areArrays = Array.isArray(a) && Array.isArray(b);

  if (!(areArrays || _typeof(a) === "object" && _typeof(b) === "object")) {
    throw new Error("Objects must be of matching types");
  }
};