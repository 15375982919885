import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export var useTheme = function useTheme(currentTheme, themes) {
  var baseHref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "/";
  var navigate = useNavigate();
  useEffect(function () {
    var _theme$name, _theme$path;

    var themeElement = document.querySelector("link#theme");

    if (themeElement) {
      themeElement.remove();
    }

    var theme = themes.find(function (t) {
      return t.name === currentTheme;
    });
    var themeName = (_theme$name = theme === null || theme === void 0 ? void 0 : theme.name) !== null && _theme$name !== void 0 ? _theme$name : "";
    document.documentElement.setAttribute("theme", themeName);
    var newUrl = new URL(location.href);
    newUrl.searchParams.set("theme", themeName);
    navigate({
      search: newUrl.search
    });
    if (!theme) return;
    themeElement = document.createElement("link");
    themeElement.id = "theme";
    themeElement.setAttribute("rel", "stylesheet");
    var styleHref = "".concat(baseHref).concat((_theme$path = theme.path) !== null && _theme$path !== void 0 ? _theme$path : "themes/".concat(theme.name, ".css"));
    themeElement.setAttribute("href", styleHref);
    document.head.appendChild(themeElement);
  }, [baseHref, currentTheme, navigate, themes]);
};