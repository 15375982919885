import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useMemo } from "react";
export var useDebounce = function useDebounce(fn) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  var debouncedFunction = useMemo(function () {
    var timeout = null;
    var lastArgs = [];

    var cancel = function cancel() {
      if (!timeout) return;
      clearTimeout(timeout);
      timeout = null;
    };

    var debounce = function debounce() {
      cancel();

      for (var _len = arguments.length, newArgs = new Array(_len), _key = 0; _key < _len; _key++) {
        newArgs[_key] = arguments[_key];
      }

      lastArgs = newArgs;
      timeout = setTimeout(function () {
        timeout = null;
        fn.apply(void 0, _toConsumableArray(lastArgs));
      }, delay);
    };

    debounce.cancel = cancel;
    return debounce;
  }, [fn, delay]);
  return debouncedFunction;
};