import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useResumeDataContext, useSessionContext } from "../context";
import { useDebounce } from "../hooks";
export var useResumeRouting = function useResumeRouting() {
  var session = useSessionContext();
  var isSignedIn = !!session;

  var _useResumeDataContext = useResumeDataContext(),
      isNewResume = _useResumeDataContext.isNewResume,
      loadResume = _useResumeDataContext.loadResume,
      contextResumeId = _useResumeDataContext.resumeId;

  var _useParams = useParams(),
      paramResumeId = _useParams.resumeId;

  var navigate = useNavigate();
  var path = useLocation().pathname;
  var isResumeLocation = /\/interactive/.test(path);
  var isHomeLocation = /^\/?$/.test(path);

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      lastContextResumeId = _useState2[0],
      setLastContextResumeId = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      lastParamResumeId = _useState4[0],
      setLastParamResumeId = _useState4[1];

  var contextChanged = lastContextResumeId !== contextResumeId;
  var paramChanged = lastParamResumeId !== paramResumeId; // if the param changed, use that as long as it has a value

  useEffect(function () {
    if (!paramChanged || !paramResumeId || !isResumeLocation) return; // cache the new value

    setLastParamResumeId(paramResumeId);
    var isNew = paramResumeId === "new"; // ignore new

    if (isNew) return;
    loadResume(paramResumeId);
  }, [isResumeLocation, loadResume, paramChanged, paramResumeId]); // if the context changed, use that to update the URL

  useEffect(function () {
    if (!contextChanged || !contextResumeId || !isResumeLocation) return; // cache the new value

    setLastContextResumeId(contextResumeId);

    if (isNewResume) {
      if (paramResumeId === "new" || !paramResumeId) return;
      navigate("new");
      return;
    } // navigate to the new ID


    navigate(contextResumeId);
  }, [contextChanged, contextResumeId, isNewResume, isResumeLocation, navigate, paramResumeId]); // if we're not signed in, only allow being on the Home page
  // debounce this so we don't do it too soon before checking the session state

  var homeCheckCallback = useCallback(function () {
    if (!isSignedIn && !isHomeLocation) {
      navigate("/");
    }
  }, [isHomeLocation, isSignedIn, navigate]);
  var debouncedHomeCheck = useDebounce(homeCheckCallback, 500);
  useEffect(function () {
    debouncedHomeCheck();
    return debouncedHomeCheck.cancel;
  }, [debouncedHomeCheck]);
};