"use strict";

exports.__esModule = true;
exports.PROVIDER = exports.ROLE = void 0;
var ROLE;

(function (ROLE) {
  ROLE["User"] = "user";
  ROLE["Admin"] = "admin";
})(ROLE = exports.ROLE || (exports.ROLE = {}));

var PROVIDER;

(function (PROVIDER) {
  PROVIDER["Google"] = "google";
})(PROVIDER = exports.PROVIDER || (exports.PROVIDER = {}));