export var Editor;

(function (Editor) {
  Editor["Certifications"] = "certifications";
  Editor["ContactInfo"] = "contact-info";
  Editor["Education"] = "education";
  Editor["Highlights"] = "highlights";
  Editor["Intro"] = "intro";
  Editor["Skills"] = "skills";
  Editor["WorkExperience"] = "work-experience";
})(Editor || (Editor = {}));