import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useState } from "react";
import { v4 } from "uuid";
export var useCacheKey = function useCacheKey() {
  var _useState = useState(function () {
    return v4();
  }),
      _useState2 = _slicedToArray(_useState, 2),
      cacheKey = _useState2[0],
      setCacheKey = _useState2[1];

  var generateNewKey = useCallback(function () {
    setCacheKey(v4());
  }, []);
  return {
    cacheKey: cacheKey,
    generateNewKey: generateNewKey
  };
};