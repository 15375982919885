"use strict";

var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;

exports.__esModule = true; // @ts-ignore

var config = (_a = globalThis.__config) !== null && _a !== void 0 ? _a : {};
var ROOT_PATH = (_b = config.ROOT_PATH) !== null && _b !== void 0 ? _b : "";
var NODE_ENV = (_g = (_f = (_c = config.NODE_ENV) !== null && _c !== void 0 ? _c : (_e = (_d = globalThis.process) === null || _d === void 0 ? void 0 : _d.env) === null || _e === void 0 ? void 0 : _e.NODE_ENV) !== null && _f !== void 0 ? _f : process.env.NODE_ENV) !== null && _g !== void 0 ? _g : "development";
var CALLBACK = (_h = config.CALLBACK) !== null && _h !== void 0 ? _h : (_k = (_j = globalThis.process) === null || _j === void 0 ? void 0 : _j.env) === null || _k === void 0 ? void 0 : _k.CALLBACK;
var environments = {
  development: {
    api: "http://localhost:3000".concat(ROOT_PATH),
    web: "http://localhost:4000".concat(ROOT_PATH),
    authCallback: "http://localhost:3000".concat(ROOT_PATH)
  },
  production: {
    api: ROOT_PATH,
    web: ROOT_PATH,
    authCallback: CALLBACK !== null && CALLBACK !== void 0 ? CALLBACK : "http://localhost:3000".concat(ROOT_PATH)
  }
};
exports["default"] = environments[NODE_ENV === "production" ? "production" : "development"];