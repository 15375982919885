import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useState } from "react";
export var useLocalStorage = function useLocalStorage(localStorageKey, defaultValue) {
  var writeDefaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var isInLocalStorage = (localStorageKey in localStorage);
  var valueFromLocalStorage = isInLocalStorage ? localStorage.getItem(localStorageKey) : undefined;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      parsedValue = _useState2[0],
      setParsedValue = _useState2[1];

  useEffect(function () {
    if (isInLocalStorage || !defaultValue || !writeDefaultValue) return;
    localStorage.setItem(localStorageKey, JSON.stringify(defaultValue));
  }, [defaultValue, isInLocalStorage, localStorageKey, writeDefaultValue]);
  useEffect(function () {
    if (!isInLocalStorage || valueFromLocalStorage === undefined || valueFromLocalStorage === null) return;

    try {
      var parsed = JSON.parse(valueFromLocalStorage);
      setParsedValue(parsed);
    } catch (ex) {
      // problem parsing the value from localStorage
      // emit and move on
      console.error(ex);
    }
  }, [isInLocalStorage, valueFromLocalStorage]);
  var setValue = useCallback(function (newValue) {
    localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    setParsedValue(newValue);
  }, [localStorageKey]);
  var clearValue = useCallback(function () {
    localStorage.removeItem(localStorageKey);
    setParsedValue(undefined);
  }, [localStorageKey]);
  return {
    clearValue: clearValue,
    setValue: setValue,
    value: parsedValue
  };
};